import { FileInputProps } from 'component/form/fileInput/fileInputProps';
import { FormControl, FormHelperText, InputAdornment, InputLabel, makeStyles, OutlinedInput } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles((theme) => ({
    root: {
        '& fieldset.MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: (props) => {
                // @ts-ignore
                if (props.isDragAccept) {
                    return theme.palette.success.main;
                }
                // @ts-ignore
                if (props.isDragReject) {
                    return theme.palette.error.main;
                }

                return theme.palette.grey[400];
            },
        },
    },
}));

const FileInput: React.FC<FileInputProps> = (props) => {
    const { name, label = name, accept, multiple, error, shrink } = props;
    const { register, unregister, setValue, watch } = useFormContext();

    const files: File[] = watch(name);

    const hasError = error !== undefined;
    const hasFiles = files !== undefined && files.length > 0;
    const labelUp = hasFiles === false ? shrink : shrink !== false;

    const onDrop = useCallback(
        (droppedFiles) => {
            setValue(name, droppedFiles, { shouldValidate: true });
        },
        [setValue, name],
    );
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, accept, multiple });
    const classes = useStyles({ isDragActive, isDragAccept, isDragReject, ...props });

    useEffect(() => {
        register(name);
        return () => {
            unregister(name);
        };
    }, [register, unregister, name]);

    const dropzoneProps = getRootProps()
    delete dropzoneProps.color

    return (
        <FormControl className={clsx(classes.root)} variant="outlined" fullWidth={true} {...(dropzoneProps as Omit<typeof dropzoneProps, 'color'>)}>
            <InputLabel error={hasError} shrink={labelUp}>
                {label}
            </InputLabel>
            <OutlinedInput
                style={{ height: '128px' }}
                label={label}
                notched={labelUp}
                error={hasError}
                inputProps={getInputProps({ style: { opacity: 0 } })}
                endAdornment={
                    error === undefined ? undefined : (
                        <InputAdornment position={'start'}>
                            <ReportProblemIcon color={'secondary'} />
                        </InputAdornment>
                    )
                }
            />
            {!!files?.length && (
                <div style={{ position: 'absolute', top: '14px', left: '14px' }}>
                    {files.map((file) => {
                        return <img key={file.name} src={URL.createObjectURL(file)} alt={file.name} style={{ height: '100px', marginRight: '14px' }} />;
                    })}
                </div>
            )}
            {hasError && (
                <FormHelperText variant="outlined" error>
                    {error}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default FileInput;
