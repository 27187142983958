import * as Sentry from '@sentry/react';
import { Address } from '../../../model/forSending/address';
import { binCleaningAddressRoute, binCleaningConfirmationRoute, binCleaningServiceRoute, binCleaningSuccessRoute } from 'route/routeBinCleaning';
import { Box, CircularProgress } from '@material-ui/core';
import { ButtonVariant } from '../../button/buttonProps';
import { convertMonthsToString } from '../../../utils/months/convertMonthsToString';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { getLocaleByLanguageStringWithUndefined } from '../../../localization/appLanguage';
import { getNavigationNameForPageEnum, PageEnum } from '../../../route/page';
import { getOrderCreateUrl } from '../../../api/url/apiBackEndUrl';
import { getPriceWithVat } from '../../../utils/vat/vat';
import { newAddedStreetId } from '../../../config/newAddedStreet';
import { ServiceType } from 'common/serviceType';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import axios, { isAxiosError } from '../../../system/axios/axios';
import ErrorMessage from 'component/errorMessage';
import i18next from 'i18next';
import PageWrapper from 'component/pageWrapper';
import Payment, { PaymentRef } from 'component/payment';
import React, { useRef, useState } from 'react';
import useBillingClient from 'store/order/hook/address/useBillingClient/useBillingClient';
import useCleaningServiceData from '../../../store/order/hook/cleaningService/useCleaningServiceData/useCleaningServiceData';
import useConfirmationData from '../../../store/order/hook/confirmation/useConfirmationData/useConfirmationData';
import useDeliveryClient from 'store/order/hook/address/useDeliveryClient/useDeliveryClient';
import useSetOrderWasCreated from 'store/order/hook/order/useSetOrderWasCreated/useSetOrderWasCreated';
import useSetPaymentDataWasSetAction from 'store/order/hook/payment/useSetPaymentDataWasSetAction/useSetPaymentDataWasSetAction';

interface CleaningService {
    readonly type: 'binCleaning';
    readonly quantity: number;
    readonly months: string | null;
    readonly tariffConfigId: number;
}

export interface Person {
    readonly firstName: string;
    readonly lastName: string;
}

export interface Company {
    readonly name: string;
    readonly vatNumber: string;
}

export interface Client {
    readonly person?: Person;
    readonly company?: Company;
    readonly emailAddress: string;
    readonly phoneNumber: string;
    readonly address: Address;
}

export interface Order {
    readonly guid?: string;
    readonly deliveryClient: Client;
    readonly billingClient?: Client;
    readonly braintreeCustomerId: string;
    readonly items: CleaningService[];
    readonly continueOnFailedPayment: boolean;
    readonly customerMessage: string;
    readonly locale: string;
    readonly onlyMailSendWithoutPay: boolean;
    readonly paymentMethodNonce: string;
    readonly totalPriceWithoutVat: number;
    readonly totalPriceWithVat: number;
}

enum CreateOrderErrorCode {
    BRAINTREE_PAYMENT_FAILED = 'BRAINTREE_PAYMENT_FAILED',
}

const CleaningPaymentPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const setPaymentData = useSetPaymentDataWasSetAction();
    const setOrderWasCreated = useSetOrderWasCreated();

    const paymentRef = useRef<PaymentRef>(null);

    const cleaningServiceData = useCleaningServiceData();
    const deliveryClient = useDeliveryClient();
    const billingAddressData = useBillingClient();
    const confirmationData = useConfirmationData();

    if (deliveryClient === undefined) {
        history.push(binCleaningAddressRoute.url());
        return null;
    }
    if (cleaningServiceData === undefined) {
        history.push(binCleaningServiceRoute.url());
        return null;
    }
    if (confirmationData === undefined) {
        history.push(binCleaningConfirmationRoute.url());
        return null;
    }

    const price = cleaningServiceData.price;

    const [payment, setPayment] = useState<{ customerId: string; nonce?: string; idempotencyKey: string } | undefined>(undefined);
    const [paymentSucceeded, setPaymentSucceeded] = useState<boolean>(false);
    const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
    const [payLater, setPayLater] = useState<boolean>(false);

    const {
        data: orderCreated,
        isLoading: createOrderIsLoading,
        refetch,
    } = useQuery<boolean>(
        ['createOrder', paymentSucceeded, payLater],
        async (): Promise<boolean> => {
            if (payment === undefined || payment === null) {
                throw new Error('Unexpected: payment is undefined');
            }
            if (deliveryClient === undefined) {
                throw new Error('Unexpected: deliveryClientData is undefined');
            }
            if (cleaningServiceData === undefined) {
                throw new Error('Unexpected: cleaningServiceData is undefined');
            }
            if (confirmationData === undefined) {
                throw new Error('Unexpected: confirmationData is undefined');
            }

            const priceWithVat = Math.round(getPriceWithVat(price) * 100) / 100;
            const priceWithoutVat = Math.round(price * 100) / 100;

            const postData: Order = {
                guid: payment.idempotencyKey,
                braintreeCustomerId: payment.customerId,
                deliveryClient: {
                    person:
                        deliveryClient.person !== undefined
                            ? {
                                  firstName: deliveryClient.person.firstName,
                                  lastName: deliveryClient.person.lastName,
                              }
                            : undefined,
                    company:
                        deliveryClient.company !== undefined
                            ? {
                                  name: deliveryClient.company.name,
                                  vatNumber: deliveryClient.company.vatNumber,
                              }
                            : undefined,
                    emailAddress: deliveryClient.email,
                    phoneNumber: deliveryClient.phone,
                    address: {
                        cityId: deliveryClient.address.city.id,
                        streetNumber: deliveryClient.address.streetNo,
                        streetId: deliveryClient.address.street.id === newAddedStreetId ? null : deliveryClient.address.street.id,
                        postalCodeId: deliveryClient.address.postalCode.id,
                        streetAdded: deliveryClient.address.street.id === 0 ? deliveryClient.address.street.name : null,
                    },
                },
                billingClient:
                    billingAddressData === null || billingAddressData === undefined
                        ? undefined
                        : {
                              person:
                                  billingAddressData.person !== undefined
                                      ? {
                                            firstName: billingAddressData.person.firstName,
                                            lastName: billingAddressData.person.lastName,
                                        }
                                      : undefined,
                              company:
                                  billingAddressData.company !== undefined
                                      ? {
                                            name: billingAddressData.company.name,
                                            vatNumber: billingAddressData.company.vatNumber,
                                        }
                                      : undefined,
                              emailAddress: billingAddressData.email,
                              phoneNumber: billingAddressData.phone,
                              address: {
                                  postalCodeId: billingAddressData.address.postalCode.id,
                                  cityId: billingAddressData.address.city.id,
                                  streetNumber: billingAddressData.address.streetNo,
                                  streetId: billingAddressData.address.street.id === 0 ? null : billingAddressData.address.street.id,
                                  streetAdded: billingAddressData.address.street.id === 0 ? billingAddressData.address.street.name : null,
                              },
                          },
                totalPriceWithVat: priceWithVat,
                totalPriceWithoutVat: priceWithoutVat,
                customerMessage: confirmationData.message === null ? '' : confirmationData.message,
                paymentMethodNonce: payment.nonce ?? '',
                items: cleaningServiceData.tariffItems.map((item) => {
                    return {
                        type: 'binCleaning',
                        quantity: item.count,
                        tariffConfigId: item.tariffConfigId,
                        months: item.selectedMonths === null ? null : convertMonthsToString(item.selectedMonths),
                    };
                }),
                continueOnFailedPayment: false,
                onlyMailSendWithoutPay: payLater,
                locale: getLocaleByLanguageStringWithUndefined(i18next.language),
            };

            let response: any = undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
            try {
                response = await axios.post(getOrderCreateUrl(), postData);
            } catch (e) {
                Sentry.captureException(e);
                console.error(e);
                if (isAxiosError(e)) {
                    e.response?.data.errors.forEach((error) => {
                        if (error.errorCode === CreateOrderErrorCode.BRAINTREE_PAYMENT_FAILED) {
                            setPaymentSucceeded(false);
                            setPaymentFailed(true);
                            setPaymentData({ isPaid: false });
                        }
                    });
                }
                return false;
            }

            const responseData = response.data;

            const success = responseData.success;

            return success === true;
        },
        {
            enabled: paymentSucceeded === true || payLater === true,
            onSuccess: async (orderCreated) => {
                Sentry.captureMessage(`Order created: ${orderCreated}`);
                if (orderCreated) {
                    await setOrderWasCreated();
                    history.push(binCleaningSuccessRoute.url());
                }
            },
        },
    );

    return (
        <PageWrapper
            page={PageEnum.cleaningPayment}
            pageTitle={getNavigationNameForPageEnum(PageEnum.cleaningPayment)}
            serviceType={ServiceType.binsCleaning}
            wizardItems={[PageEnum.cleaningAddress, PageEnum.cleaningService, PageEnum.cleaningConfirm, PageEnum.cleaningPayment]}
            price={{
                label: flc(t('order.pricePerYear')),
                amount: price,
            }}
            secondButton={{
                arrowContinue: true,
                onClick: () => {
                    if (createOrderIsLoading) {
                        return;
                    }
                    if (paymentFailed) {
                        setPayLater(true);
                        return;
                    }
                    if (paymentSucceeded && !orderCreated) {
                        refetch();
                        return;
                    }
                    if (paymentRef.current === null) {
                        return;
                    }
                    paymentRef.current.submit();
                },
                text: paymentFailed === true ? t('order.navigation.payLater') : t('order.navigation.continue'),
                variant: paymentFailed === true ? ButtonVariant.outlined : ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    history.push(binCleaningConfirmationRoute.url());
                },
                text: t('order.navigation.back'),
                variant: ButtonVariant.outlined,
            }}
        >
            {createOrderIsLoading === true && <CircularProgress />}
            {createOrderIsLoading !== true && paymentFailed === true && (
                <Box marginTop={3}>
                    <ErrorMessage text={t('order.screen.payment.error.failed2')} />
                </Box>
            )}
            {createOrderIsLoading !== true && paymentSucceeded === false && paymentFailed === false && payLater === false && (
                <Payment
                    ref={paymentRef}
                    price={price}
                    personalInformation={{
                        firstName: deliveryClient.person?.firstName,
                        lastName: deliveryClient.person?.lastName,
                        company: deliveryClient.company?.name,
                        email: deliveryClient.email,
                        phone: deliveryClient.phone,
                    }}
                    onSuccess={(customerId, nonce) => {
                        const idempotencyKey = uuidv4();
                        setPayment({ customerId, nonce, idempotencyKey });
                        Sentry.addBreadcrumb({
                            category: 'payment',
                            message: `Idempotency key ${idempotencyKey} generated`,
                            level: Sentry.Severity.Info,
                        });
                        setPaymentSucceeded(true);
                    }}
                    onError={(customerId) => {
                        const idempotencyKey = uuidv4();
                        setPayment({ customerId, idempotencyKey });
                        Sentry.addBreadcrumb({
                            category: 'payment',
                            message: `Idempotency key ${idempotencyKey} generated`,
                            level: Sentry.Severity.Info,
                        });
                        setPaymentFailed(true);
                    }}
                />
            )}
        </PageWrapper>
    );
};

export default CleaningPaymentPage;
