import * as Sentry from '@sentry/react';
import { Box, CircularProgress } from '@material-ui/core';
import { ButtonVariant } from '../../button/buttonProps';
import { CreatePestOrderErrorCode, Order } from 'component/page/insectControlPayment';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { getLocaleByLanguageStringWithUndefined } from '../../../localization/appLanguage';
import { getNavigationNameForPageEnum, PageEnum } from '../../../route/page';
import { getPestControlOrderCreateUrl } from '../../../api/url/apiBackEndUrl';
import { getPriceWithVat } from '../../../utils/vat/vat';
import { newAddedStreetId } from '../../../config/newAddedStreet';
import { ratControlAddressRoute, ratControlConfirmRoute, ratControlServiceRoute, ratControlSuccessRoute } from 'route/routeRatControl';
import { ServiceType } from 'common/serviceType';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import axios, { isAxiosError } from '../../../system/axios/axios';
import ErrorMessage from 'component/errorMessage';
import i18next from 'i18next';
import PageWrapper from 'component/pageWrapper';
import Payment, { PaymentRef } from 'component/payment';
import React, { useRef, useState } from 'react';
import useBillingClient from 'store/order/hook/address/useBillingClient/useBillingClient';
import useConfirmationData from '../../../store/order/hook/confirmation/useConfirmationData/useConfirmationData';
import useDeliveryClient from 'store/order/hook/address/useDeliveryClient/useDeliveryClient';
import useRatControlData from 'store/order/hook/ratControlService/useRatControlData/useRatControlData';
import useSetOrderWasCreated from 'store/order/hook/order/useSetOrderWasCreated/useSetOrderWasCreated';
import useSetPaymentDataWasSetAction from 'store/order/hook/payment/useSetPaymentDataWasSetAction/useSetPaymentDataWasSetAction';

const RatControlPaymentPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const setPaymentData = useSetPaymentDataWasSetAction();
    const setOrderWasCreated = useSetOrderWasCreated();

    const paymentRef = useRef<PaymentRef>(null);

    const ratControlData = useRatControlData();
    const deliveryClient = useDeliveryClient();
    const billingAddressData = useBillingClient();
    const confirmationData = useConfirmationData();

    if (deliveryClient === undefined) {
        history.push(ratControlAddressRoute.url());
        return null;
    }
    if (ratControlData === undefined) {
        history.push(ratControlServiceRoute.url());
        return null;
    }
    if (confirmationData === undefined) {
        history.push(ratControlConfirmRoute.url());
        return null;
    }

    const price = ratControlData.price;

    const [payment, setPayment] = useState<{ customerId: string; nonce?: string; idempotencyKey: string } | undefined>(undefined);
    const [paymentSucceeded, setPaymentSucceeded] = useState<boolean>(false);
    const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
    const [payLater, setPayLater] = useState<boolean>(false);

    const {
        data: orderCreated,
        isLoading: createOrderIsLoading,
        refetch,
    } = useQuery<boolean>(
        ['createOrder', paymentSucceeded, payLater],
        async (): Promise<boolean> => {
            if (payment === undefined || payment === null) {
                throw new Error('Unexpected: payment is undefined');
            }
            if (deliveryClient === undefined) {
                throw new Error('Unexpected: deliveryClientData is undefined');
            }
            if (ratControlData === undefined) {
                throw new Error('Unexpected: ratControlData is undefined');
            }
            if (confirmationData === undefined) {
                throw new Error('Unexpected: confirmationData is undefined');
            }

            const priceWithVat = Math.round(getPriceWithVat(price) * 100) / 100;
            const priceWithoutVat = Math.round(price * 100) / 100;

            const base64encode = (file: File) =>
                new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onabort = () => reject();
                    reader.onerror = () => reject();
                    reader.onload = (event) => {
                        // @ts-ignore
                        const dataUrl: string = event.target.result;
                        resolve(dataUrl.slice(dataUrl.indexOf(',') + 1));
                    };
                    reader.readAsDataURL(file);
                });

            const mapImages = async (images: File[]) =>
                Promise.all(
                    images.map(async (image: File) => {
                        return {
                            name: image.name,
                            content: await base64encode(image),
                        };
                    }),
                );

            const postData: Order = {
                guid: payment.idempotencyKey,
                braintreeCustomerId: payment.customerId,
                deliveryClient: {
                    person:
                        deliveryClient.person !== undefined
                            ? {
                                  firstName: deliveryClient.person.firstName,
                                  lastName: deliveryClient.person.lastName,
                              }
                            : undefined,
                    company:
                        deliveryClient.company !== undefined
                            ? {
                                  name: deliveryClient.company.name,
                                  vatNumber: deliveryClient.company.vatNumber,
                              }
                            : undefined,
                    emailAddress: deliveryClient.email,
                    phoneNumber: deliveryClient.phone,
                    address: {
                        cityId: deliveryClient.address.city.id,
                        streetNumber: deliveryClient.address.streetNo,
                        streetId: deliveryClient.address.street.id === newAddedStreetId ? null : deliveryClient.address.street.id,
                        postalCodeId: deliveryClient.address.postalCode.id,
                        streetAdded: deliveryClient.address.street.id === 0 ? deliveryClient.address.street.name : null,
                    },
                },
                billingClient:
                    billingAddressData === null || billingAddressData === undefined
                        ? undefined
                        : {
                              person:
                                  billingAddressData.person !== undefined
                                      ? {
                                            firstName: billingAddressData.person.firstName,
                                            lastName: billingAddressData.person.lastName,
                                        }
                                      : undefined,
                              company:
                                  billingAddressData.company !== undefined
                                      ? {
                                            name: billingAddressData.company.name,
                                            vatNumber: billingAddressData.company.vatNumber,
                                        }
                                      : undefined,
                              emailAddress: billingAddressData.email,
                              phoneNumber: billingAddressData.phone,
                              address: {
                                  postalCodeId: billingAddressData.address.postalCode.id,
                                  cityId: billingAddressData.address.city.id,
                                  streetNumber: billingAddressData.address.streetNo,
                                  streetId: billingAddressData.address.street.id === 0 ? null : billingAddressData.address.street.id,
                                  streetAdded: billingAddressData.address.street.id === 0 ? billingAddressData.address.street.name : null,
                              },
                          },
                totalPriceWithVat: priceWithVat,
                totalPriceWithoutVat: priceWithoutVat,
                customerMessage: confirmationData.message === null ? '' : confirmationData.message,
                paymentMethodNonce: payment.nonce ?? '',
                items: [
                    {
                        type: 'ratControl',
                        tariffConfigId: ratControlData.tariffConfigId,
                        quantity: 1,
                        problem: ratControlData.problem,
                        description: ratControlData.description,
                        images: await mapImages(ratControlData.images),
                    },
                ],
                continueOnFailedPayment: false,
                onlyMailSendWithoutPay: payLater,
                locale: getLocaleByLanguageStringWithUndefined(i18next.language),
            };

            let response: any = undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
            try {
                response = await axios.post(getPestControlOrderCreateUrl(), postData);
            } catch (e) {
                Sentry.captureException(e);
                console.error(e);
                if (isAxiosError(e)) {
                    e.response?.data.errors.forEach((error) => {
                        if (error.errorCode === CreatePestOrderErrorCode.BRAINTREE_PAYMENT_FAILED) {
                            setPaymentSucceeded(false);
                            setPaymentFailed(true);
                            setPaymentData({ isPaid: false });
                        }
                    });
                }
                return false;
            }

            const responseData = response.data;

            const success = responseData.success;

            console.log(success);

            return success as boolean;
        },
        {
            enabled: paymentSucceeded === true || payLater === true,
            onSuccess: async (orderCreated) => {
                Sentry.captureMessage(`Order created: ${orderCreated}`);
                if (orderCreated) {
                    await setOrderWasCreated();
                    history.push(ratControlSuccessRoute.url());
                }
            },
        },
    );

    return (
        <PageWrapper
            page={PageEnum.ratControlPayment}
            pageTitle={getNavigationNameForPageEnum(PageEnum.ratControlPayment)}
            serviceType={ServiceType.ratControl}
            wizardItems={[PageEnum.ratControlAddress, PageEnum.ratControlService, PageEnum.ratControlConfirm, PageEnum.ratControlPayment]}
            price={{
                label: flc(t('order.price')),
                amount: price,
            }}
            secondButton={{
                arrowContinue: true,
                onClick: () => {
                    if (createOrderIsLoading) {
                        return;
                    }
                    if (paymentFailed) {
                        setPayLater(true);
                        return;
                    }
                    if (paymentSucceeded && !orderCreated) {
                        refetch();
                        return;
                    }
                    if (paymentRef.current === null) {
                        return;
                    }
                    paymentRef.current.submit();
                },
                text: paymentFailed === true ? t('order.navigation.payLater') : t('order.navigation.continue'),
                variant: paymentFailed === true ? ButtonVariant.outlined : ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    history.push(ratControlConfirmRoute.url());
                },
                text: t('order.navigation.back'),
                variant: ButtonVariant.outlined,
            }}
        >
            {createOrderIsLoading === true && <CircularProgress />}
            {createOrderIsLoading !== true && paymentFailed === true && (
                <Box marginTop={3}>
                    <ErrorMessage text={t('order.screen.payment.error.failed2')} />
                </Box>
            )}
            {createOrderIsLoading !== true && paymentSucceeded === false && paymentFailed === false && payLater === false && (
                <Payment
                    ref={paymentRef}
                    price={price}
                    personalInformation={{
                        firstName: deliveryClient.person?.firstName,
                        lastName: deliveryClient.person?.lastName,
                        company: deliveryClient.company?.name,
                        email: deliveryClient.email,
                        phone: deliveryClient.phone,
                    }}
                    onSuccess={(customerId, nonce) => {
                        const idempotencyKey = uuidv4();
                        setPayment({ customerId, nonce, idempotencyKey });
                        Sentry.addBreadcrumb({
                            category: 'payment',
                            message: `Idempotency key ${idempotencyKey} generated`,
                            level: Sentry.Severity.Info,
                        });
                        setPaymentSucceeded(true);
                    }}
                    onError={(customerId) => {
                        const idempotencyKey = uuidv4();
                        setPayment({ customerId, idempotencyKey });
                        Sentry.addBreadcrumb({
                            category: 'payment',
                            message: `Idempotency key ${idempotencyKey} generated`,
                            level: Sentry.Severity.Info,
                        });
                        setPaymentFailed(true);
                    }}
                />
            )}
        </PageWrapper>
    );
};

export default RatControlPaymentPage;
