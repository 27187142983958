import { SwitchTabsProps } from 'component/switchTabs/switchTabsProps';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import React from 'react';

const RootTabs = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: 3,
        borderRadius: 100,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderWidth: 1,
        display: 'inline-flex',
        minHeight: 24,
    },
    indicator: {
        backgroundColor: 'transparent',
    },
}))(Tabs);

const TabItem = withStyles((theme) => ({
    root: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        opacity: 1,
        borderRadius: 100,
        padding: '6px 18px',
        minHeight: 24,
    },
    selected: {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.secondary.main,
    },
}))(Tab);

const SwitchTabs: React.FC<SwitchTabsProps> = (props) => {
    const { items, label, value, onChange } = props;

    return (
        <RootTabs value={value} onChange={onChange} aria-label={label}>
            {items.map((item) => (
                <TabItem key={item.value} value={item.value} label={item.name} />
            ))}
        </RootTabs>
    );
};

export default SwitchTabs;
